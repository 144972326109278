<template>
  <div class="mdc-layout-grid__inner" id="twinsouls">
    <div class="mdc-layout-grid__cell--span-12">
      <div class="TwinSouls">
        <h3>TwinSouls</h3>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'TwinSouls',
  components: {
  },
  data() {
    return {

    };
  },
}
</script>

<style lang="scss"></style>